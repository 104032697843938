import { create } from 'zustand';
import { toastr } from 'react-redux-toastr';
import { getChildrenAndImportance } from '../api';

type ChildrenAndImportanceState = {
  childrenAndImportance: any;
  isLoading: boolean;
  error: string | null;
  fetchChildrenAndImportance: (uuid: string) => Promise<void>;
  sendChildrenAndImportanceToBackend: () => Promise<void>;
};

export const useChildrenAndImportanceStore = create<ChildrenAndImportanceState>((set) => ({
  childrenAndImportance: null,
  isLoading: false,
  error: null,

  fetchChildrenAndImportance: async (uuid: string) => {
    set({ isLoading: true, error: null });

    try {
      const response = await getChildrenAndImportance(uuid);
      set({
        childrenAndImportance: response.data,
        isLoading: false,
        error: null,
      });
    } catch (error) {
      set({
        isLoading: false,
        error: 'Ocorreu um erro ao obter as informações de crianças e importância.',
      });
      toastr.error('Erro', 'Ocorreu um erro ao obter as informações de crianças e importância.');
    }
  },

  sendChildrenAndImportanceToBackend: async () => {
    const { childrenAndImportance } = useChildrenAndImportanceStore.getState();
    
    if (!childrenAndImportance) {
      toastr.error('Erro', 'Não há dados para enviar.');
      return;
    }

    set({ isLoading: true, error: null });

    try {
      const response = await fetch('/setChildrenAndImportance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(childrenAndImportance),
      });

      if (response.ok) {
        toastr.success('Sucesso', 'Dados enviados com sucesso!');
        set({
          isLoading: false,
          error: null,
        });
      } else {
        const errorData = await response.json();
        set({
          isLoading: false,
          error: errorData.message || 'Falha ao enviar os dados.',
        });
        toastr.error('Erro', errorData.message || 'Falha ao enviar os dados.');
      }
    } catch (error) {
      set({
        isLoading: false,
        error: 'Erro ao enviar os dados para o servidor.',
      });
      toastr.error('Erro', 'Erro ao enviar os dados para o servidor.');
    }
  },
}));
