import { toastr } from 'react-redux-toastr';

import { call, put } from 'redux-saga/effects';

import config from '../../config';
import { Types, Creators } from '../actionCreators';
import {
  GetAssetDataRequestAction,
  GetAssetDetailsRequestAction,
  GetAssetCalibrationRequestAction,
  GetAssetIndicatorsRequestAction,
  PatchAssetDetailsRequestAction,
  PatchAssetCalibrationRequestAction,
  UpdateAssetBlockAction,
  UpdateAssetIndicatorWeightRequestAction,
  GetAssetAlertRequestAction,
  UpdateAssetAlertRequestAction,
  UpdateAssetRequestAction,
} from './types';

interface GetAssetDataAction extends GetAssetDataRequestAction {
  type: typeof Types.GET_ASSET_DATA_REQUEST;
}

export function* getAssetData(action: GetAssetDataAction) {
  try {
    const assetData = yield config.api.getAssetData(action.identifier);
    yield put(Creators.getAssetDataSuccess(assetData.data));
  } catch (error) {
    const msg = 'Ocorreu um erro ao buscar as informações do ativo.';
    toastr.error('Erro', msg);
    yield put(Creators.getAssetDataFailure(msg));
  }
}

interface GetAssetIndicatorsAction extends GetAssetIndicatorsRequestAction {
  type: typeof Types.GET_ASSET_INDICATORS_REQUEST;
}

export function* getAssetIndicators({ uuid }: GetAssetIndicatorsAction) {
  try {
    const response = yield config.api.getAssetIndicatorsDetails(uuid);
    yield put(Creators.getAssetIndicatorsSuccess(response.data));
  } catch (error) {
    const msg = 'Ocorreu um erro ao recuperar os indicadores do ativo.';
    toastr.error('Erro', msg);
    yield put(Creators.getAssetIndicatorsFailure(msg));
  }
}

interface UpdateAssetBlockRequestAction extends UpdateAssetBlockAction {
  type: typeof Types.UPDATE_ASSET_BLOCK;
}

export function* updateAssetBlock(action: UpdateAssetBlockRequestAction) {
  try {
    yield config.api.updateAssetBlock(action.identifier, action.blocked);

    const assetData = yield config.api.getAssetData(action.identifier);
    yield put(Creators.getAssetDataSuccess(assetData.data));
  } catch (error) {
    const msg = 'Ocorreu um erro ao atualizar as informações do ativo.';
    toastr.error('Erro', msg);
    yield put(Creators.updateAssetBlockFailure(msg));
  }
}

interface UpdateAssetIndicatorWeightAction
  extends UpdateAssetIndicatorWeightRequestAction {
  type: typeof Types.UPDATE_ASSET_INDICATOR_WEIGHT_REQUEST;
}

export function* updateAssetIndicatorWeight({
  indicator,
}: UpdateAssetIndicatorWeightAction) {
  try {
    const response = yield config.api.updateAssetIndicatorWeight(indicator);
    yield put(Creators.updateAssetIndicatorWeightSuccess(response.data));
  } catch (err) {
    const msg = 'Ocorreu um erro ao atualizar o peso do indicador';
    toastr.error('Erro', msg);
    yield put(Creators.updateAssetIndicatorWeightFailure(msg));
  }
}

interface GetAssetDetailsAction extends GetAssetDetailsRequestAction {
  type: typeof Types.GET_ASSET_DETAILS_REQUEST;
}

export function* getAssetDetails({ uuid }: GetAssetDetailsAction) {
  try {
    const response = yield call(config.api.getAssetDetails, uuid);
    yield put(Creators.getAssetDetailsSuccess(response.data));
  } catch (err) {
    const msg =
      'Ocorreu um erro ao recuperar os detalhes do ativo. Recarregue a página para tentar novamente.';
    toastr.error('Erro', msg);
    yield put(Creators.getAssetDetailsFailure(msg));
  }
}

interface PatchAssetDetailsAction extends PatchAssetDetailsRequestAction {
  type: typeof Types.PATCH_ASSET_DETAILS_REQUEST;
}

export function* patchAssetDetails({ details, uuid }: PatchAssetDetailsAction) {
  try {
    const response = yield call(config.api.patchAssetDetails, uuid, details);

    yield put(Creators.patchAssetDetailsSuccess(response.data));
  } catch (err) {
    const msg =
      'Ocorreu um erro ao atualizar os detalhes do ativo. Tente novamente mais tarde.';
    toastr.error('Erro', msg);
    yield put(Creators.patchAssetDetailsFailure(msg));
  }
}

interface GetAssetAlertAction extends GetAssetAlertRequestAction {
  type: typeof Types.GET_ASSET_ALERT_REQUEST;
}

export function* getAssetAlert({ uuid }: GetAssetAlertAction) {
  try {
    const response = yield call(config.api.getAssetAlert, uuid);

    yield put(Creators.getAssetAlertSuccess(response.data));
  } catch (err) {
    const msg =
      'Ocorreu um erro ao recuperar dados para alertas do ativo. Tente novamente mais tarde.';
    toastr.error('Erro', msg);
    yield put(Creators.getAssetAlertFailure(msg));
  }
}

interface UpdateAssetAlertAction extends UpdateAssetAlertRequestAction {
  type: typeof Types.UPDATE_ASSET_ALERT_REQUEST;
}

export function* updateAssetAlert({ alertData }: UpdateAssetAlertAction) {
  try {
    const response = yield call(config.api.updateAssetAlert, alertData);

    yield put(Creators.updateAssetAlertSuccess(response.data));
  } catch (err) {
    const msg =
      'Ocorreu um erro ao atualizar os dados para alertas do ativo. Tente novamente mais tarde.';
    toastr.error('Erro', msg);
    yield put(Creators.updateAssetAlertFailure(msg));
  }
}

interface UpdateAssetAction extends UpdateAssetRequestAction {
  type: typeof Types.UPDATE_ASSET_REQUEST;
}

export function* updateAsset({ assetData }: UpdateAssetAction) {
  try {
    const response = yield call(config.api.updateAsset, assetData);

    yield put(Creators.updateAssetSuccess(response.data));
  } catch (err) {
    const msg =
      'Ocorreu um erro ao atualizar os dados do ativo e/ou modelo. Tente novamente mais tarde.';
    toastr.error('Erro', msg);
    yield put(Creators.updateAssetFailure(msg));
  }
}

interface GetAssetCalibrationAction extends GetAssetCalibrationRequestAction {
  type: typeof Types.GET_ASSET_CALIBRATION_REQUEST;
}

export function* getAssetCalibration({ uuid }: GetAssetCalibrationAction) {
  try {
    const response = yield call(config.api.getAssetCalibration, uuid);
    yield put(Creators.getAssetCalibrationSuccess(response.data));
  } catch (err) {
    const msg =
      'Ocorreu um erro ao recuperar os detalhes do ativo. Recarregue a página para tentar novamente.';
    toastr.error('Erro', msg);
    yield put(Creators.getAssetCalibrationFailure(msg));
  }
}

interface PatchAssetCalibrationAction extends PatchAssetCalibrationRequestAction {
  type: typeof Types.PATCH_ASSET_CALIBRATION_REQUEST;
}

export function* patchAssetCalibration({ calibration, uuid }: PatchAssetCalibrationAction) {
  try {
    const response = yield call(config.api.patchAssetCalibration, uuid, calibration);

    yield put(Creators.patchAssetCalibrationSuccess(response.data));
  } catch (err) {
    const msg =
      'Ocorreu um erro ao atualizar os detalhes do ativo. Tente novamente mais tarde.';
    toastr.error('Erro', msg);
    yield put(Creators.patchAssetCalibrationFailure(msg));
  }
}
