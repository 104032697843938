import { createReducer, createActions } from 'reduxsauce';

import { AssetInfo } from '../../models/AssetInfo';
import {
  AssetDataState,
  AssetDataTypes,
  AssetDataCreators,
  GetAssetDataSuccessAction,
  GetAssetDataFailureAction,
  GetAssetIndicatorsSuccessAction,
  GetAssetIndicatorsFailureAction,
  UpdateAssetIndicatorWeightSuccessAction,
  UpdateAssetIndicatorWeightFailureAction,
  GetAssetDetailsSuccessAction,
  GetAssetDetailsFailureAction,
  PatchAssetDetailsSuccessAction,
  PatchAssetDetailsFailureAction,
  GetAssetAlertFailureAction,
  UpdateAssetAlertSuccessAction,
  UpdateAssetAlertFailureAction,
  GetAssetAlertSuccessAction,
  SetAssetTimePeriodAction,
  SetAssetPrevTimePeriodAction,
  GetAssetCalibrationSuccessAction,
  GetAssetCalibrationFailureAction,
  PatchAssetCalibrationSuccessAction,
  PatchAssetCalibrationFailureAction,
  UpdateAssetSuccessAction,
  UpdateAssetFailureAction,
} from './types';

const { Types, Creators } = createActions<AssetDataTypes, AssetDataCreators>({
  getAssetDataRequest: ['identifier'],
  getAssetDataSuccess: ['assetData'],
  getAssetDataFailure: ['error'],
  updateAssetBlock: ['identifier', 'blocked'],
  updateAssetBlockFailure: ['error'],
  getAssetIndicatorsRequest: ['uuid'],
  getAssetIndicatorsSuccess: ['indicators'],
  getAssetIndicatorsFailure: ['error'],
  updateAssetIndicatorWeightRequest: ['indicator'],
  updateAssetIndicatorWeightSuccess: ['indicators'],
  updateAssetIndicatorWeightFailure: ['error'],
  getAssetDetailsRequest: ['uuid'],
  getAssetDetailsSuccess: ['details'],
  getAssetDetailsFailure: ['error'],
  patchAssetDetailsRequest: ['uuid', 'details'],
  patchAssetDetailsSuccess: ['details'],
  patchAssetDetailsFailure: ['error'],
  getAssetAlertRequest: ['uuid'],
  getAssetAlertSuccess: ['alertData'],
  getAssetAlertFailure: ['error'],
  updateAssetAlertRequest: ['alertData'],
  updateAssetAlertSuccess: ['alertData'],
  updateAssetAlertFailure: ['error'],
  updateAssetRequest: ['assetData'],
  updateAssetSuccess: ['assetData'],
  updateAssetFailure: ['error'],
  setAssetTimePeriod: ['assetTimePeriod'],
  setAssetPrevTimePeriod: ['assetPrevTimePeriod'],
  getAssetCalibrationRequest: ['uuid'],
  getAssetCalibrationSuccess: ['calibration'],
  getAssetCalibrationFailure: ['error'],
  patchAssetCalibrationRequest: ['uuid', 'calibration'],
  patchAssetCalibrationSuccess: ['calibration'],
  patchAssetCalibrationFailure: ['error'],
});

const INITIAL_STATE: AssetDataState = {
  isLoading: false,
  areIndicatorsLoading: false,
  areDetailsLoading: false,
  isCalibrationLoading: false,
  detailsError: '',
  calibrationError: '',
  data: {} as AssetInfo,
  indicators: [],
  error: '',
  isError: false,
  alerts: {
    error: null,
    isLoading: false,
  },
  assetTimePeriod: {
    startTime: 'now-30m',
    endTime: 'now',
  },
  assetPrevTimePeriod: {
    startTime: 'now-30m',
    endTime: 'now',
  },
};

const getAssetDataRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    error: '',
    isError: false,
  };
};

const getAssetDataSuccess = (
  state = INITIAL_STATE,
  action: GetAssetDataSuccessAction
) => {
  return {
    ...state,
    isLoading: false,
    data: action.assetData,
  };
};

const getAssetDataFailure = (
  state = INITIAL_STATE,
  action: GetAssetDataFailureAction
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
    isError: true,
    data: {} as AssetInfo,
  };
};

const updateAssetBlock = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    error: '',
    isError: false,
  };
};

const updateAssetBlockFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
  };
};

const getAssetIndicatorsRequest = (state = INITIAL_STATE) => ({
  ...state,
  indicators: [],
  error: '',
  isError: false,
});

const getAssetIndicatorsSuccess = (
  state = INITIAL_STATE,
  { indicators }: GetAssetIndicatorsSuccessAction
) => ({
  ...state,
  indicators,
  error: '',
});

const getAssetIndicatorsFailure = (
  state = INITIAL_STATE,
  { error }: GetAssetIndicatorsFailureAction
) => ({
  ...state,
  error,
  isError: true,
});

const updateAssetIndicatorWeightRequest = (state = INITIAL_STATE) => ({
  ...state,
  areIndicatorsLoading: true,
  error: '',
});

const updateAssetIndicatorWeightSuccess = (
  state = INITIAL_STATE,
  { indicators }: UpdateAssetIndicatorWeightSuccessAction
) => ({
  ...state,
  indicators,
  areIndicatorsLoading: false,
  error: '',
});

const updateAssetIndicatorWeightFailure = (
  state = INITIAL_STATE,
  { error }: UpdateAssetIndicatorWeightFailureAction
) => ({
  ...state,
  areIndicatorsLoading: false,
  error,
});

const getAssetDetailsRequest = (state = INITIAL_STATE) => ({
  ...state,
  areDetailsLoading: true,
  detailsError: '',
});

const getAssetDetailsSuccess = (
  state = INITIAL_STATE,
  { details }: GetAssetDetailsSuccessAction
) => ({
  ...state,
  areDetailsLoading: false,
  data: {
    ...state.data,
    details,
  },
});

const getAssetDetailsFailure = (
  state = INITIAL_STATE,
  { error }: GetAssetDetailsFailureAction
) => ({
  ...state,
  areDetailsLoading: false,
  detailsError: error,
  data: {
    ...state.data,
    details: undefined,
  },
});

const patchAssetDetailsRequest = (state = INITIAL_STATE) => ({
  ...state,
  areDetailsLoading: true,
  detailsError: '',
});

const patchAssetDetailsSuccess = (
  state = INITIAL_STATE,
  { details }: PatchAssetDetailsSuccessAction
) => ({
  ...state,
  areDetailsLoading: false,
  data: {
    ...state.data,
    details,
  },
});

const patchAssetDetailsFailure = (
  state = INITIAL_STATE,
  { error }: PatchAssetDetailsFailureAction
) => ({
  ...state,
  areDetailsLoading: true,
  detailsError: error,
  data: {
    ...state.data,
    details: undefined,
  },
});

const getAssetAlertRequest = (state = INITIAL_STATE) => ({
  ...state,
  alerts: {
    ...state.alerts,
    isLoading: true,
    error: null,
    data: undefined,
  },
});

const getAssetAlertSuccess = (
  state = INITIAL_STATE,
  { alertData }: GetAssetAlertSuccessAction
) => ({
  ...state,
  alerts: {
    ...state.alerts,
    isLoading: false,
    data: alertData,
    error: null,
  },
});

const getAssetAlertFailure = (
  state = INITIAL_STATE,
  { error }: GetAssetAlertFailureAction
) => ({
  ...state,
  alerts: {
    ...state.alerts,
    isLoading: false,
    error,
  },
});

const updateAssetAlertRequest = (state = INITIAL_STATE) => ({
  ...state,
  alerts: {
    ...state.alerts,
    isLoading: true,
    error: null,
  },
});

const updateAssetAlertSuccess = (
  state = INITIAL_STATE,
  { alertData }: UpdateAssetAlertSuccessAction
) => ({
  ...state,
  alerts: {
    ...state.alerts,
    isLoading: false,
    data: alertData,
    error: null,
  },
});

const updateAssetAlertFailure = (
  state = INITIAL_STATE,
  { error }: UpdateAssetAlertFailureAction
) => ({
  ...state,
  alerts: {
    ...state.alerts,
    isLoading: false,
    error,
  },
});

const updateAssetRequest = (state = INITIAL_STATE) => ({
  ...state,
  data: {
    ...state.data,
    isLoading: true,
    error: null,
  },
});


const updateAssetSuccess = (
  state = INITIAL_STATE,
  { assetData }: UpdateAssetSuccessAction
) => ({
  ...state,
  data: {
    ...state.data,
    isLoading: false,
    description: assetData.description,
    calibrable: assetData.calibrable,
    limits: assetData.limits,
    model: assetData.model,
    modelDesciption: assetData.modelDescription,
    importance: assetData.importance,
    criticality: assetData.criticality,
    weights: assetData.weights,
    error: null,
  },
});

const updateAssetFailure = (
  state = INITIAL_STATE,
  { error }: UpdateAssetFailureAction
) => ({
  ...state,
  alerts: {
    ...state.alerts,
    isLoading: false,
    error,
  },
});

const setAssetTimePeriod = (
  state = INITIAL_STATE,
  { assetTimePeriod }: SetAssetTimePeriodAction
) => ({
  ...state,
  assetTimePeriod,
});

const setAssetPrevTimePeriod = (
  state = INITIAL_STATE,
  { assetPrevTimePeriod }: SetAssetPrevTimePeriodAction
) => ({
  ...state,
  assetPrevTimePeriod,
});

const getAssetCalibrationRequest = (state = INITIAL_STATE) => ({
  ...state,
  isCalibrationLoading: true,
  calibrationError: '',
});

const getAssetCalibrationSuccess = (
  state = INITIAL_STATE,
  { calibration }: GetAssetCalibrationSuccessAction
) => ({
  ...state,
  isCalibrationLoading: false,
  data: {
    ...state.data,
    calibration,
  },
});

const getAssetCalibrationFailure = (
  state = INITIAL_STATE,
  { error }: GetAssetCalibrationFailureAction
) => ({
  ...state,
  isCalibrationLoading: false,
  calibrationError: error,
  data: {
    ...state.data,
    calibration: undefined,
  },
});

const patchAssetCalibrationRequest = (state = INITIAL_STATE) => ({
  ...state,
  isCalibrationLoading: true,
  calibrationError: '',
});

const patchAssetCalibrationSuccess = (
  state = INITIAL_STATE,
  { calibration }: PatchAssetCalibrationSuccessAction
) => ({
  ...state,
  isCalibrationLoading: false,
  data: {
    ...state.data,
    calibration,
  },
});

const patchAssetCalibrationFailure = (
  state = INITIAL_STATE,
  { error }: PatchAssetCalibrationFailureAction
) => ({
  ...state,
  isCalibrationLoading: true,
  calibrationError: error,
  data: {
    ...state.data,
    calibration: undefined,
  },
});

const HANDLERS = {
  [Types.GET_ASSET_DATA_REQUEST]: getAssetDataRequest,
  [Types.GET_ASSET_DATA_SUCCESS]: getAssetDataSuccess,
  [Types.GET_ASSET_DATA_FAILURE]: getAssetDataFailure,
  [Types.UPDATE_ASSET_BLOCK]: updateAssetBlock,
  [Types.UPDATE_ASSET_BLOCK_FAILURE]: updateAssetBlockFailure,
  [Types.GET_ASSET_INDICATORS_REQUEST]: getAssetIndicatorsRequest,
  [Types.GET_ASSET_INDICATORS_SUCCESS]: getAssetIndicatorsSuccess,
  [Types.GET_ASSET_INDICATORS_FAILURE]: getAssetIndicatorsFailure,
  [Types.UPDATE_ASSET_INDICATOR_WEIGHT_REQUEST]: updateAssetIndicatorWeightRequest,
  [Types.UPDATE_ASSET_INDICATOR_WEIGHT_SUCCESS]: updateAssetIndicatorWeightSuccess,
  [Types.UPDATE_ASSET_INDICATOR_WEIGHT_FAILURE]: updateAssetIndicatorWeightFailure,
  [Types.GET_ASSET_DETAILS_REQUEST]: getAssetDetailsRequest,
  [Types.GET_ASSET_DETAILS_SUCCESS]: getAssetDetailsSuccess,
  [Types.GET_ASSET_DETAILS_FAILURE]: getAssetDetailsFailure,
  [Types.PATCH_ASSET_DETAILS_REQUEST]: patchAssetDetailsRequest,
  [Types.PATCH_ASSET_DETAILS_SUCCESS]: patchAssetDetailsSuccess,
  [Types.PATCH_ASSET_DETAILS_FAILURE]: patchAssetDetailsFailure,
  [Types.GET_ASSET_ALERT_REQUEST]: getAssetAlertRequest,
  [Types.GET_ASSET_ALERT_SUCCESS]: getAssetAlertSuccess,
  [Types.GET_ASSET_ALERT_FAILURE]: getAssetAlertFailure,
  [Types.UPDATE_ASSET_ALERT_REQUEST]: updateAssetAlertRequest,
  [Types.UPDATE_ASSET_ALERT_SUCCESS]: updateAssetAlertSuccess,
  [Types.UPDATE_ASSET_ALERT_FAILURE]: updateAssetAlertFailure,
  [Types.UPDATE_ASSET_REQUEST]: updateAssetRequest,
  [Types.UPDATE_ASSET_SUCCESS]: updateAssetSuccess,
  [Types.UPDATE_ASSET_FAILURE]: updateAssetFailure,
  [Types.SET_ASSET_TIME_PERIOD]: setAssetTimePeriod,
  [Types.SET_ASSET_PREV_TIME_PERIOD]: setAssetPrevTimePeriod,
  [Types.GET_ASSET_CALIBRATION_REQUEST]: getAssetCalibrationRequest,
  [Types.GET_ASSET_CALIBRATION_SUCCESS]: getAssetCalibrationSuccess,
  [Types.GET_ASSET_CALIBRATION_FAILURE]: getAssetCalibrationFailure,
  [Types.PATCH_ASSET_CALIBRATION_REQUEST]: patchAssetCalibrationRequest,
  [Types.PATCH_ASSET_CALIBRATION_SUCCESS]: patchAssetCalibrationSuccess,
  [Types.PATCH_ASSET_CALIBRATION_FAILURE]: patchAssetCalibrationFailure,
};

const Reducer = createReducer(INITIAL_STATE, HANDLERS);

export {
  Creators as AssetDataCreators,
  Types as AssetDataTypes,
  Reducer as AssetDataReducer,
};
