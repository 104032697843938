import { create } from 'zustand';
import { toastr } from 'react-redux-toastr';
import { getAllCompanies, updateUserCompany } from '../api';

type Company = {
  company_uuid: string;
  name: string;
};

type CompanyState = {
  companies: Company[] | null;
  currentCompany: string | null;
  isLoading: boolean;
  error: string | null;
  fetchAllCompanies: (user: any) => Promise<void>;
  updateCompany: (userUuid: string, companyUuid: string) => Promise<boolean>;
};

export const useCompanyStore = create<CompanyState>((set) => ({
  companies: null,
  isLoading: false,
  error: null,
  currentCompany: null,

  fetchAllCompanies: async (user: any) => {
    set({ isLoading: true, error: null });

    try {
      const response = await getAllCompanies(user);
      const companiesArray = Object.entries(response.data.companies).map(([uuid, name]) => ({
        company_uuid: uuid,
        name: name as string,
      }));

      set({
        companies: companiesArray,
        currentCompany: response.data.currentCompany,
        isLoading: false,
        error: null,
      });
    } catch (error) {
      const errorMessage = 'Ocorreu um erro ao obter a lista de empresas. Tente novamente mais tarde.';
      set({
        isLoading: false,
        error: errorMessage,
      });
      toastr.error('Erro', errorMessage);
    }
  },
  updateCompany: async (userUuid, companyUuid) => {
    set({ isLoading: true });

    try {
      await updateUserCompany(userUuid, companyUuid);
      set({ currentCompany: companyUuid, isLoading: false });
      return true;
    } catch (error) {
      set({ isLoading: false });
      toastr.error('Erro', 'Não foi possível atualizar a empresa. Tente novamente mais tarde.');
      return false;
    }
  },
}));
  
  
