import styled from 'styled-components';
import { deviceSizes } from '../../styles/supportedDevices';

export const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100%;

  padding: 24px;

  @media (max-width: ${deviceSizes.mobileL}px) {
    padding: 12px;
  }
`;

export const TooltipSpan = styled.span`
  font-size: 12px;
  line-height: 1.5rem;
`;

export const OptionContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 6px 24px 0px 24px;
  overflow-y: auto;
  overflow-x: hidden;

  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);

  background-color: white;

  .companyForm{
    min-width: 15em;
    margin-top: 1em;
  }

  svg.help-icon {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const OptionText = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: 28px;
    font-weight: 500;
    color: black;
    margin-right: 6px;
  }

  @media (max-width: ${deviceSizes.mobileL}px) {
    h1 {
      font-size: 21px;
      font-weight: 500;
      color: black;
    }
  }
`;
