import styled from 'styled-components';

import { supportedDevices } from '../../../../../../../../../styles/supportedDevices';

export const Container = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
  overflow-y: auto;

  max-height: 500px;
  max-width: 300px;
  padding: 28px 24px;

  transition: all 0.5s;

  &.loading {
    max-width: 150px;
    max-height: 150px;
  }

  .error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 100px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 2rem;
    }
  }

  .title{
    display: flex;
    justify-content: flex-start;
  }

  .limits{
    margin-bottom: 10px;
  }
  
  .not-first-title{
    margin-top: 30px;
  }

  .campo > .MuiFormControlLabel-root{
    margin-left: 5px
  }

  .input-texto{
    width: 100%;
    text-align: left;
  }

  .input-indicador{
    width: 50px;
  }

  .indicators{
    display: flex;
  }

  .campo{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center
}

  .divider{
    background: black;
  }

  .checkbox-icon {
    font-size: 2rem;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 2rem;
    }

    .MuiFormControlLabel-root {
      margin: unset;
    }
  }

  .limit-input{
    margin-left: 5px;
  }

  .numerical-input{
    width: 100px;
    text-align: center
  }

  .numerical-input > .MuiInputBase-input{
  text-align: center
  }

  .email-select-container {
    width: 100%;

    margin: 20px 0 15px;
  }
  .form-column{
    display: flex;
    flex-direction: column;
  }

  .dropdownSelectCriticality {
    width: 100px;
    text-align: center;
  }

  .form-row {
    display: grid;
    grid-template-areas:
      'label check'
      'alertSlider alertSlider'
      'returnSlider returnSlider';
    grid-template-columns: 1fr;
    grid-column-gap: 15px;

    margin: 15px 0;

    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h6 {
      font-size: 1.6rem;
    }

    .key-form {
      font-size: 1.8rem;

      &.name-area {
        grid-area: label;
      }

      &.alertValue-area {
        grid-area: alertSlider;
      }

      &.returnValue-area {
        grid-area: returnSlider;
      }

      &.enable-area {
        grid-area: check;
      }

      .slider-label {
        font-size: 1.4rem;
      }

      .slider-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        width: 100%;

        & > :first-child {
          width: 70%;
        }

        & > :last-child {
          width: 20%;
        }
      }
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 30px;

    & > * {
      height: 40px;
      width: 100px;

      span {
        font-size: 1.4rem;
      }
    }
  }

  @media ${supportedDevices.mobileM} {
    max-height: 625px;
    max-width: 350px;
  }

  @media ${supportedDevices.mobileL} {
    max-height: 725px;
    max-width: 350px;
  }

  @media ${supportedDevices.tablet} {
    max-height: 825px;
    max-width: 1200px;
    padding: 32px 36px;

    .form-row {
      grid-template-areas: 'label alertSlider returnSlider check';
      grid-template-columns: 200px repeat(2, minmax(300px, 1fr)) 200px;

      .key-form {
        font-size: 1.4rem;
      }
    }

    .action-buttons {
      & > * {
        height: 42px;
        width: 120px;
      }
    }
  }
`;

export const SliderValueLabel = styled.span`
  font-size: 12px;
`;
