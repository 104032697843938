import React, { useState, useEffect, useCallback } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useLocation, useHistory } from 'react-router-dom';

import {
  Backdrop,
  Fade,
  Tooltip,
  Select,
  MenuItem,
  InputBase,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { DateRange as DateRangeIcon, ArrowRightAlt } from '@material-ui/icons';
import {
  toDate,
  format,
  setHours,
  setMinutes,
  getHours,
  getMinutes,
  subMinutes,
  subHours,
  subDays,
} from 'date-fns';

import { Creators } from '../../store/actionCreators';
import { DataAnalysisName } from '../../store/dataAnalysis/types';
import { AppState } from '../../store/reducers';
import { ReportNames } from '../../store/reports/types';
import { DateRange, DateRangePicker } from '../Pickers/RangeDatePicker';
import { TimeRange } from '../Pickers/RangeDatePicker/types';

import { supportedDevices } from '../../styles/supportedDevices';
import {
  DesktopContainer,
  Button,
  CustomModal,
  TooltipSpan,
  MobileContainer,
} from './styles';

export interface TimePeriod {
  startTime: string | number | null;
  endTime: string | number | null;
}

export interface DateFilterItem {
  id: string;
  content: string | JSX.Element;
  tooltip: string;
  onClick?: () => void;
}

export interface DateFilterProps {
  fixedFilters: DateFilterItem[];
  initialValue?: string;
  breakpoint?: string;
  timePeriodState?:
    | 'treeHealth'
    | 'reports'
    | 'dataAnalysis'
    | 'assetData'
    | 'historyIndicator';
  reportName?: ReportNames;
  dataAnalysisName?: DataAnalysisName;
  // useLocalTimePeriod?: boolean;
  // localTimePeriod?: TimePeriod;
  // setLocalTimePeriod?: React.Dispatch<React.SetStateAction<TimePeriod>>;
}

export const DateFilter: React.FC<DateFilterProps> = ({
  fixedFilters,
  initialValue,
  breakpoint = supportedDevices.tablet,
  timePeriodState = 'treeHealth',
  reportName = 'healthAndIndicatorsPerLogicalElement',
  // useLocalTimePeriod = false,
  // localTimePeriod = null,
  // setLocalTimePeriod = null,
}) => {
  // @ts-ignore
  const { timePeriod, prevTimePeriod } = useSelector(
    (state: AppState) => state[timePeriodState]
  );
  // @ts-ignore
  const asset = useSelector((state: AppState) => state['assetData']);

  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [selectedTime, setSelectedTime] = useState<TimeRange>({
    startTime: '00:00',
    endTime: '00:00',
  });

  const changeDate = useCallback(
    (time: TimePeriod) => {
      if (
        timePeriodState !== 'assetData' &&
        prevTimePeriod?.startTime != null &&
        prevTimePeriod?.endTime != null &&
        time?.startTime === prevTimePeriod?.startTime &&
        time?.endTime === prevTimePeriod?.endTime
      ) {
        return;
      }
      if (timePeriodState === 'treeHealth') {
        /**
         * The line below was kept in case we observe a bug in the future.
         * TODO: To be removed if app functions without any bugs
         */
        // dispatch(Creators.setTreeHealthPrevTimePeriod(timePeriod));
        dispatch(Creators.setTreeHealthTimePeriod(time));
        dispatch(Creators.getTreeHealthRequest());
      } else if (timePeriodState === 'reports') {
        dispatch(Creators.getReportsRequest(time, reportName));
      } else if (timePeriodState === 'dataAnalysis') {
        dispatch(Creators.setTimePeriod(time));
      } else if (timePeriodState === 'assetData') {
        if (
          asset.assetPrevTimePeriod?.startTime != null &&
          asset.assetPrevTimePeriod?.endTime != null &&
          time?.startTime === asset.assetPrevTimePeriod?.startTime &&
          time?.endTime === asset.assetPrevTimePeriod.endTime
        ) {
          return;
        }
        dispatch(Creators.setAssetPrevTimePeriod(asset.assetTimePeriod));
        dispatch(Creators.setAssetTimePeriod(time));
      } else if (timePeriodState === 'historyIndicator') {
        dispatch(Creators.setHistoryTimePeriod(time));
      }
    },
    [
      timePeriodState,
      dispatch,
      reportName,
      prevTimePeriod,
      timePeriod,
      asset.assetTimePeriod,
      asset.assetPrevTimePeriod,
    ]
  );

  function selectNewDate(
    startTime: string | number | null,
    endTime: string | number | null
  ) {
    //  Resets current notification
    dispatch(Creators.setCurrentNotification(null));

    changeDate({ startTime, endTime });
  }

  //  Sets the timePeriod to 7 days in case it was 30 days
  useEffect(() => {
    if (
      location.pathname.startsWith('/admin/dashboard/ativo') &&
      String(timePeriod?.startTime).endsWith('30d')
    ) {
      changeDate({ startTime: 'now-7d', endTime: 'now' });
    }
  }, [location, dispatch, timePeriod?.startTime, changeDate]);

  // Retrieves START and END DATES from URL PARAMS and updates Grafana dashboard timeframe accordingly
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const startDateStr = params.get('startDate');
    const endDateStr = params.get('endDate');

    if (
      startDateStr === undefined ||
      endDateStr === undefined ||
      startDateStr == null ||
      endDateStr == null ||
      !selectedTime ||
      !selectedTime?.startTime ||
      !selectedTime?.endTime
    )
      return;
    if (startDateStr.includes('now') || startDateStr.includes('yesterday')) {
      selectNewDate(startDateStr, endDateStr);
    } else {
      if (
        isNaN(new Date(startDateStr).getTime()) ||
        isNaN(new Date(endDateStr).getTime())
      ) {
        return;
      }

      const startDateRaw = new Date(startDateStr);
      const endDateRaw = new Date(endDateStr);

      const startDate = setHours(
        setMinutes(startDateRaw, Number(selectedTime?.startTime.slice(-2))),
        Number(selectedTime?.startTime.slice(0, 2))
      );
      const endDate = setHours(
        setMinutes(endDateRaw, Number(selectedTime?.endTime.slice(-2))),
        Number(selectedTime?.endTime.slice(0, 2))
      );

      selectNewDate(startDate.getTime(), endDate.getTime());

      startDate && endDate && setDateRange({ startDate, endDate });
    }
  }, [location.search]);

  function handleFixedPeriodClick(fixedPeriodParam: string) {
    let start = fixedPeriodParam === 'ATUAL' ? null : `now-${fixedPeriodParam}`;
    let end = fixedPeriodParam === 'ATUAL' ? null : 'now';
    if (fixedPeriodParam === 'yesterday') {
      start = 'yesterday';
      end = 'today';
    }

    if (
      location.pathname.includes('dashboard') &&
      fixedPeriodParam !== 'ATUAL'
    ) {
      // Updating URL params with the selected fixedPeriodParam range
      const params = new URLSearchParams(location.search); // Retrieving the current URL params
      params.set('startDate', start); // Adding startDate to the list of url params
      params.set('endDate', end); // Adding endDate to the list of url params
      history.push(`${location.pathname}?${params}`); // Updating the url
    }

    selectNewDate(start, end);
  }

  function checkIfFilterIsSelected(item: string) {
    const startTime =
      timePeriodState === 'assetData'
        ? asset.assetTimePeriod?.startTime
        : timePeriod?.startTime;
    const endTime =
      timePeriodState === 'assetData'
        ? asset.assetTimePeriod?.endTime
        : timePeriod?.endTime;
    if (
      (!startTime && !endTime && item === 'ATUAL') ||
      (typeof startTime === typeof '' &&
        item === String(startTime)?.slice(4, startTime.length)) ||
      (typeof startTime === typeof '' && item === String(startTime)) ||
      (typeof startTime === typeof 0 && item === 'CUSTOM')
    )
      return true;
    return false;
  }

  const showPicker = () => setIsPickerOpen(true);

  const hidePicker = () => setIsPickerOpen(false);

  function handleOpenPicker() {
    if (
      timePeriod?.startTime &&
      timePeriod?.endTime &&
      typeof timePeriod?.startTime === 'number' &&
      typeof timePeriod?.endTime === 'number'
    ) {
      setDateRange({
        startDate: toDate(timePeriod?.startTime),
        endDate: toDate(timePeriod?.endTime),
      });
      setSelectedTime({
        startTime: `${`0${getHours(timePeriod?.startTime)}`.slice(
          -2
        )}:${`0${getMinutes(timePeriod?.endTime)}`.slice(-2)}`,
        endTime: `${`0${getHours(timePeriod?.endTime)}`.slice(
          -2
        )}:${`0${getMinutes(timePeriod?.endTime)}`.slice(-2)}`,
      });
    }

    showPicker();
  }

  function handleCancelPress() {
    hidePicker();
    setDateRange(null);
  }

  function handleApplyPress() {
    hidePicker();

    if (dateRange) {
      const startDate = setHours(
        setMinutes(
          dateRange!.startDate!,
          Number(selectedTime?.startTime.slice(-2))
        ),
        Number(selectedTime?.startTime.slice(0, 2))
      );
      const endDate = setHours(
        setMinutes(dateRange!.endDate!, Number(selectedTime.endTime.slice(-2))),
        Number(selectedTime.endTime.slice(0, 2))
      );

      if (location.pathname.includes('dashboard')) {
        // Updating URL params with the selected date range
        const params = new URLSearchParams(location.search); // Retrieving the current URL params
        params.set('startDate', dateRange!.startDate!.toISOString()); // Adding startDate to the list of url params
        params.set('endDate', dateRange!.endDate!.toISOString()); // Adding endDate to the list of url params
        history.push(`${location.pathname}?${params}`); // Updating the url
      }

      selectNewDate(startDate.getTime(), endDate.getTime());
    } else
      toastr.error('Período Inválido', 'Por favor selecione um período válido');
  }

  function handleChangeSelect(
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) {
    handleFixedPeriodClick(String(event.target.value));
  }

  const filters: DateFilterItem[] = [
    ...fixedFilters.map((filter) => {
      return {
        ...filter,
        onClick: () => handleFixedPeriodClick(filter.id),
      };
    }),
    {
      id: 'CUSTOM',
      content: <DateRangeIcon fontSize="large" />,
      onClick: handleOpenPicker,
      tooltip: 'Data personalizada',
    },
  ];

  return (
    <Media
      queries={{
        tablet: `${breakpoint}`,
      }}
    >
      {(matches) => (
        <>
          {matches.tablet && (
            <DesktopContainer data-testid="dateFilterContainer">
              {filters.map((item) => (
                <Tooltip
                  key={item?.id}
                  title={<TooltipSpan>{item.tooltip}</TooltipSpan>}
                >
                  <Button
                    isSelected={checkIfFilterIsSelected(item?.id)}
                    onClick={item.onClick}
                  >
                    {item.content}
                  </Button>
                </Tooltip>
              ))}
            </DesktopContainer>
          )}
          {!matches.tablet && (
            <MobileContainer>
              <Select
                value={
                  filters.find((item) => checkIfFilterIsSelected(item?.id))?.id
                }
                onChange={handleChangeSelect}
                fullWidth
                input={
                  <InputBase
                    fullWidth
                    className="select-input"
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          onClick={filters[filters.length - 1].onClick}
                        >
                          <DateRangeIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                }
              >
                {filters
                  .filter((item) => item.id !== 'CUSTOM')
                  .map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.tooltip.toUpperCase()}
                    </MenuItem>
                  ))}
                {checkIfFilterIsSelected('CUSTOM') && (
                  <MenuItem value="CUSTOM">
                    {format(new Date(timePeriod?.startTime!), 'dd/MM')}
                    <ArrowRightAlt />
                    {format(new Date(timePeriod?.endTime!), 'dd/MM')}
                  </MenuItem>
                )}
              </Select>
            </MobileContainer>
          )}
          <CustomModal
            open={isPickerOpen}
            onClose={handleCancelPress}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={isPickerOpen}>
              <div className="outline-0">
                <div className="date-picker-container">
                  <DateRangePicker
                    open={isPickerOpen}
                    onChange={(range) => setDateRange(range)}
                    i18n={{
                      months: [
                        'Jan',
                        'Fev',
                        'Mar',
                        'Abr',
                        'Mai',
                        'Jun',
                        'Jul',
                        'Ago',
                        'Set',
                        'Out',
                        'Nov',
                        'Dez',
                      ],
                      days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                    }}
                    initialDateRange={
                      dateRange
                        ? {
                            startDate: dateRange.startDate,
                            endDate: dateRange.endDate,
                          }
                        : undefined
                    }
                    minDate={toDate(new Date('01/01/2019'))}
                    maxDate={toDate(Date.now())}
                    onCancelClick={handleCancelPress}
                    onApplyClick={handleApplyPress}
                    showSingleMonth={!matches.tablet}
                    selectedTime={selectedTime}
                    handleTimeClick={setSelectedTime}
                  />
                </div>
              </div>
            </Fade>
          </CustomModal>
        </>
      )}
    </Media>
  );
};
