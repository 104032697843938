import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import {
  CircularProgress,
  Collapse,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Tooltip,
  Badge,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  Lock,
  LockOpen,
  ChatBubble,
  ChatBubbleOutline,
} from '@material-ui/icons';
import { format } from 'date-fns';

import errorIcon from '../../../../../../../../assets/imgs/error-icon.png';
import paretoOnIcon from '../../../../../../../../assets/new_icons/ic-switch-pareto-select.svg';
import paretoOffIcon from '../../../../../../../../assets/new_icons/ic-switch-pareto-unselect.svg';
import tableOnIcon from '../../../../../../../../assets/new_icons/ic-switch-table-select.svg';
import tableOffIcon from '../../../../../../../../assets/new_icons/ic-switch-table-unselect.svg';
import {
  Pareto,
  ParetoValue,
} from '../../../../../../../../components/charts/Pareto';
import { Table } from '../../../../../../../../components/charts/Table';
import { ToggleChartButton } from '../../../../../../../../components/ToggleChartButton';
import { HealthAndIndicatorsPerElement } from '../../../../../../../../models/Reports';
import { Creators } from '../../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../../store/reducers';
import { generateCsv } from '../../../../../../../../utils/methods';
import { Header } from '../../Header';

import {
  Container,
  CustomAccordion,
  ParetoTooltip,
  TooltipSpan,
} from './styles';
import { useAuthStore } from '../../../../../../../../zustand/AuthStore';
import { isEmpty } from 'lodash';

const fixedTimePeriods = [
  ['24h', '24 H', '24 Horas'],
  ['7d', '7 D', '7 Dias'],
  ['15d', '15 D', '15 Dias'],
].map((time) => {
  return {
    id: time[0],
    content: time[1],
    tooltip: time[2],
  };
});

/** Creates pareto compatible data for an indicator with callback  */
function generateParetoData(
  data: HealthAndIndicatorsPerElement[],
  indicator: string,
  resolve: (value: [ParetoValue[], string]) => void
) {
  /** Builds tooltip and converts to html string -> The topics and values arrays must have the same size  */
  const buildTooltip = (name: string, topics: string[], values: string[]) =>
    ReactDOMServer.renderToStaticMarkup(
      <ParetoTooltip>
        <h3 className="title">{name}</h3>
        {topics.map((topic, index) => (
          <div className="topic-data-container" key={topic}>
            <p className="topic">{`${topic}: `}</p>
            <p className="data">{`${values[index]}%`}</p>
          </div>
        ))}
      </ParetoTooltip>
    );

  const arr: ParetoValue[] = [];

  // Getting name and value of each asset
  const arrWithoutTotal: [string, number, string][] = [];
  data.forEach((item) => {
    if (item.indicatorsValues[indicator] !== null) {
      const penalty = Number(
        Math.fround((1 - item.indicatorsValues[indicator]) * 100).toFixed(2)
      );
      arrWithoutTotal.push([
        item.name,
        penalty > 100 ? 100 : penalty,
        buildTooltip(
          item.name,
          [indicator, 'Penalidade'],
          [
            Math.fround(item.indicatorsValues[indicator] * 100).toFixed(2),
            String(penalty > 100 ? 100 : penalty),
          ]
        ),
      ]);
    }
  });

  // Sorting list to get biggest faults and slice only the top 20
  const sortedArr = arrWithoutTotal.sort((a, b) => b[1] - a[1]);

  // Getting the sum of all 20 values

  const sum = sortedArr.reduce(
    (previous, current) => previous + (current[1] || 0),
    0
  );

  // Generating the total for each asset based on the total sum
  sortedArr.forEach((item, index) => {
    const penaltySum = Number(
      Math.fround(
        (item[1] / sum) * 100 + (index === 0 ? 0 : arr[index - 1][3])
      ).toFixed(2)
    );
    arr.push([
      ...item,
      penaltySum > 100 ? 100 : penaltySum,
      buildTooltip(
        '',
        ['Total'],
        [String(penaltySum > 100 ? 100 : penaltySum)]
      ),
    ]);
  });
  resolve([arr, indicator]);
}

export const IndicatorsPerElementLogical: React.FC<RouteComponentProps> = () => {
  const { reports } = useSelector((state: AppState) => state);
  const { isLoading, error, data, timePeriod, elementUuid } = reports;
  const { perElement } = data;
  const dispatch = useDispatch();

  const user = useAuthStore((state) => state.user);

  const history = useHistory();

  const [filter, setFilter] = useState<string | null>();
  const [selectedChart, setSelectedChart] = useState<'table' | 'pareto'>(
    'table'
  );
  const [selectedElement] = useState('Ativos');
  const [paretoData, setParetoData] = useState<{
    [indicator: string]: ParetoValue[];
  }>({});

  useEffect(() => {
    dispatch(Creators.setNavigationOption('treeView'));
    dispatch(Creators.setTreeOptions('all'));
  }, [dispatch]);

  useEffect(() => {
    if ((Array.isArray(elementUuid)) && user.tree) {
      if (user.defaultTreeElementUuid) {
        dispatch(Creators.setElementUuid(user.defaultTreeElementUuid));
      } else {
        dispatch(Creators.setElementUuid(user.tree.uuid));
      }
    }
  }, [user.tree, dispatch, elementUuid]);

  useEffect(() => {
    dispatch(
      Creators.getReportsRequest(
        { startTime: 'now-24h', endTime: 'now' },
        'healthAndIndicatorsPerLogicalElement',
        null,
        true
      )
    );
  }, [dispatch, elementUuid]);

  // Generates the pareto chart data for eache indicator on data change
  useEffect(() => {
    if (perElement && !isEmpty(perElement)) {
      Promise.all<[ParetoValue[], string]>(
        Object.keys(perElement[0]?.indicatorsValues).map(
          (indicator) =>
            new Promise((resolve) =>
              generateParetoData(perElement, indicator, resolve)
            )
        )
      ).then((results) => {
        const hash: { [indicator: string]: ParetoValue[] } = {};

        results.forEach((item) => {
          hash[item[1]] = [...item[0]];
        });
        setParetoData(hash);
      });
    }
  }, [perElement]);

  function buildSearchOptions() {
    let arr: string[] = [];
    if (perElement)
      arr = [
        ...perElement.map((item) => item.name),
        ...perElement.map((item) => item.description),
      ];
    return arr;
  }

  function buildErrorAndLoader() {
    if (error)
      return (
        <div className="error">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      );
    return (
      <div className="loader">
        <CircularProgress size={50} />
      </div>
    );
  }

 function buildEmptyAsset() {
      return (
        <div className="error">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Não foram encontrados ativos lógicos no nível selecionado.</h3>
        </div>
      );
  }

  function buildHealth(health: number) {
    if (health === null) return '-';
    return `${String(Math.fround(health * 100).toFixed(2))}%`;
  }

  function buildBlockedIcon(isBlocked: boolean) {
    return (
      <Tooltip
        title={
          <TooltipSpan>
            {isBlocked ? 'Ativo bloqueado' : 'Ativo desbloqueado'}
          </TooltipSpan>
        }
      >
        {isBlocked ? <Lock /> : <LockOpen />}
      </Tooltip>
    );
  }

  function buildNotificationIcon(numNotification: number, msg: string | null) {
    const icon = (
      <Badge badgeContent={numNotification} color="secondary">
        {numNotification && msg ? <ChatBubble /> : <ChatBubbleOutline />}
      </Badge>
    );

    return numNotification && msg ? (
      <Tooltip title={<TooltipSpan>{msg}</TooltipSpan>}>{icon}</Tooltip>
    ) : (
      icon
    );
  }

  function createTableRow(item: HealthAndIndicatorsPerElement, isCsv = false) {
    const row = [
      item.name,
      item.description,
      buildHealth(item.health),
      ...Object.entries(item.indicatorsValues)
        .filter((e) => e[0] !== 'Saúde')
        .map((e) => buildHealth(e[1])),
    ];
    if (isCsv)
      return [
        item.blocked ? 'Bloqueado' : 'Desbloqueado',
        item.notificationObservation
          ? [...item.notificationObservation]
              .map((char) => (char === '\n' ? '' : char))
              .join('')
          : '-',
        ...row,
      ];

    return [
      <div className="obs-icons">
        {buildBlockedIcon(item.blocked)}
        {buildNotificationIcon(
          item.totalNotificationInAnalysis,
          item.notificationObservation
        )}
      </div>,
      ...row,
    ];
  }

  function buildHeaders(
    assets: HealthAndIndicatorsPerElement[],
    isCsv = false
  ) {
    const headers = [
      selectedElement,
      'Descrição',
      'Saúde',
      ...Object.keys(assets[0].indicatorsValues).filter((k) => k !== 'Saúde'),
    ];

    if (isCsv) return ['Status de Bloqueio', 'Notificações', ...headers];

    return ['Observações', ...headers];
  }

  function buildRows(assets: HealthAndIndicatorsPerElement[]) {
    return assets.filter((item) =>
      filter
        ? item.name.includes(filter) || item.description.includes(filter)
        : true
    );
  }

  function handleDownloadCsv() {
    if (perElement) {
      const timeStamp =
        typeof timePeriod.startTime === 'string'
          ? timePeriod.startTime.split('-')[1]
          : `${format(new Date(timePeriod.startTime!), 'dd/MM/yyyy')}-${format(
              new Date(timePeriod.endTime!),
              'dd/MM/yyyy'
            )}`;

      generateCsv(
        buildHeaders(perElement, true),
        buildRows(perElement).map((row) => createTableRow(row, true)) as any,
        `Relatorio_${selectedElement}_Indicadores_${timeStamp}.csv`
      );
    }
  }

  function handleSearchBarChange(item: string | string[] | null) {
    if (!Array.isArray(item)) setFilter(item);
  }

  function onRowPress(element: HealthAndIndicatorsPerElement) {
    dispatch(Creators.setElementUuid(element.uuid));
    history.push('/admin/relatorios/saudeeindicadores/porperiodo');
  }

  function buildToggleChartButtons(iconSize: number) {
    return (
      <>
        <ToggleChartButton
          onIcon={tableOnIcon}
          offIcon={tableOffIcon}
          alt="Mostrar ou esconder tabela"
          isSelected={selectedChart === 'table'}
          onToggle={() => setSelectedChart('table')}
          iconSize={iconSize}
          height={48}
          name="Tabela"
        />
        <ToggleChartButton
          onIcon={paretoOnIcon}
          offIcon={paretoOffIcon}
          alt="Mostrar ou esconder gráfico de pareto"
          isSelected={selectedChart === 'pareto'}
          onToggle={() => setSelectedChart('pareto')}
          iconSize={iconSize}
          height={48}
          name="Pareto"
        />
      </>
    );
  }

  if (!elementUuid && reports.navigationOption == "menus")
    return (
    <Container className="no-element">
        <h2>Selecione um relatório para iniciar</h2>
      </Container>
    );
  if (!elementUuid && reports.navigationOption == "treeView")
    return (
      <Container className="no-element">
        <h2>Selecione um nível na árvore</h2>
      </Container>
    );

  return (
    <Container>
      <Header
        title="Indicadores por Ativos Lógicos"
        timeList={fixedTimePeriods}
        handleDownloadCsv={handleDownloadCsv}
        handleSearchBarChange={handleSearchBarChange}
        buildToggleChartsButtons={buildToggleChartButtons}
        datePicker="normal"
        hideSearchBar={selectedChart === 'pareto'}
        reportName="healthAndIndicatorsPerLogicalElement"
        buildSearchOptions={buildSearchOptions}
      />
      {error || isLoading ? (
        buildErrorAndLoader()
        ) : (
        perElement === null || isEmpty(perElement) ?
          (buildEmptyAsset()
          ) : (
          <div className="charts">
            <Collapse in={selectedChart === 'table'} timeout="auto" unmountOnExit>
              <div className="table">
                <Table
                  headers={buildHeaders(perElement)}
                  rows={buildRows(perElement)}
                  createRow={createTableRow}
                  onClick={onRowPress}
                />
              </div>
            </Collapse>
            <Collapse
              in={selectedChart === 'pareto'}
              timeout="auto"
              unmountOnExit
            >
              <div className="pareto">
                {[
                  'Saúde',
                  ...Object.entries(perElement[0].indicatorsValues || [])
                    .filter((entry) => entry[0] !== 'Saúde')
                    .map((entry) => entry[0]),
                ].map((indicator, index) => (
                  <CustomAccordion defaultExpanded={index === 0} key={indicator}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{indicator}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="pareto-container">
                        <Pareto
                          subtitle="Penalidade"
                          values={paretoData[indicator] || []}
                          xAxisTitle={selectedElement}
                          yAxisTitle="Penalidade"
                        />
                      </div>
                    </AccordionDetails>
                  </CustomAccordion>
                ))}
              </div>
            </Collapse>
          </div>
        )
      )}
    </Container>
  );
};
