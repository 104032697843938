import React, { useEffect } from 'react';
import { RouteComponentProps} from 'react-router-dom';
import { Container, OptionContainer, OptionText, TooltipSpan } from './styles';
import { FormControl, InputLabel, MenuItem, Select, Slide, Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { TreeViewWrapper } from './components/TreeWrapper/TreeWrapper';
import { useCompanyStore } from '../../zustand/CompanyStore';
import { useAuthStore } from '../../zustand/AuthStore'
import { ROLES } from '../../utils/authTypes';
import { toastr } from 'react-redux-toastr';

export const Configurations: React.FC<RouteComponentProps> = (props) => {
  const { companies, currentCompany, isLoading, fetchAllCompanies, updateCompany, error } = useCompanyStore();

  const { user } = useAuthStore();
  const keycloak = useAuthStore.getState().keycloak;

  useEffect(() => {
    if (user.uid) {
      fetchAllCompanies(user.uid);
    }
  }, [fetchAllCompanies, user.uid]);

  useEffect(() => {
    fetchAllCompanies(user.uid); 
  }, [fetchAllCompanies]);

  const handleCompanyChange = async (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedCompanyUuid = e.target.value as string;
  
    try {
      const isSuccess = await updateCompany(user.uid, selectedCompanyUuid);
  
      if (isSuccess) {
        window.location.assign('/admin/dashboard');
      } else {
        toastr.error('Erro', 'Não foi possível atualizar a empresa.');
      }
    } catch (error) {
      toastr.error('Erro', 'Ocorreu um erro inesperado ao atualizar a empresa.');
    }
  };

  return (
<Container style={{ display: 'ruby' }}>
    {keycloak.hasRealmRole(ROLES.DEV) && (
      <Slide in timeout={500} direction="left" style={{ height: '14vh'}}>
        <OptionContainer>
          <OptionText>
            <h1>Seleção de empresa</h1>
            <Tooltip
              title={
                <TooltipSpan>
                  {'Selecione o nível inicial do gráfico no Dashboard para o seu usuário'}
                </TooltipSpan>
              }
            >
              <Help className="help-icon" />
            </Tooltip>
          </OptionText>

          <FormControl
            variant="outlined"
            className='companyForm'
          >
            <InputLabel id="dropdown-label">Empresa</InputLabel>
            <Select
              labelId="dropdown-label"
              value={currentCompany || ''}
              onChange={handleCompanyChange}
              label="Empresa"
              disabled={isLoading}
            >
              <MenuItem value="" disabled>
                Selecione uma empresa
              </MenuItem>
              {companies &&
                companies.map((company) => (
                  <MenuItem key={company.company_uuid} value={company.company_uuid}>
                    {company.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </OptionContainer>
      </Slide>
    )}

      <Slide in timeout={500} direction="left" style={{ height: 'fit-content' }}>
        <OptionContainer>
          <OptionText>
            <h1>Nível Inicial do Sunburst</h1>
            <Tooltip
              title={
                <TooltipSpan>
                  {'Selecione o nível inicial do gráfico no Dashboard para o seu usuário'}
                </TooltipSpan>
              }
            >
              <Help className="help-icon" />
            </Tooltip>
          </OptionText>
          <TreeViewWrapper />
        </OptionContainer>
      </Slide>
    </Container>
  );
};
