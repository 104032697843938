import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { CircularProgress, IconButton, MenuItem } from '@material-ui/core';
import { Check, Edit, Close } from '@material-ui/icons';

import { CustomDialog } from '../../../../../../../components/CustomDialog';
import {
  CustomInput,
  CustomSelect,
} from '../../../../../../../components/CustomSelect';
import { DateFilter } from '../../../../../../../components/DateFilter';
import config from '../../../../../../../config';
import { AssetIndicator } from '../../../../../../../models/AssetInfo';
import { Creators } from '../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../store/reducers';

import { supportedDevices } from '../../../../../../../styles/supportedDevices';
import { Container, WeightContainer } from './styles';

const timeList = [
  ['30m', '30 M', '30 Minutos'],
  ['1h', '1 H', '1 Hora'],
  ['6h', '6 H', '6 Horas'],
  ['12h', '12 H', '12 Horas'],
  ['24h', '24 H', '24 Horas'],
  ['7d', '7 D', '7 Dias'],
];

interface HealthTabProps {
  isLogicalAsset: boolean;
}

export const HealthTab: React.FC<HealthTabProps> = ({ isLogicalAsset }) => {
  const { assetData } = useSelector((state: AppState) => state);
  const dispatch = useDispatch();

  const [selectedIndicator, setSelectedIndicator] = useState<AssetIndicator>(
    {} as AssetIndicator
  );
  const [selectedWeight, setSelectedWeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isLogicalAsset)
      dispatch(Creators.getAssetIndicatorsRequest(assetData.data.uuid));
  }, [dispatch, assetData.data.uuid, isLogicalAsset]);

  function handleEditingMode(asset: AssetIndicator, weight: number) {
    setSelectedIndicator(asset);
    setSelectedWeight(weight);
  }

  function handleCancelPress() {
    setSelectedIndicator({} as AssetIndicator);
  }

  function handleIndicatorWeightChange(
    event: React.ChangeEvent<{ value: unknown }>
  ) {
    setSelectedWeight(event.target.value as number);
  }

  function toggleModalOpen(open = false) {
    setIsModalOpen(open);
  }

  function handleCheckPress(indicator: AssetIndicator) {
    if (indicator.weight !== selectedWeight) toggleModalOpen(true);
    else toastr.info('Alterar o peso', 'O peso selecionado é igual ao salvo');
  }

  function handleModalConfirmClick() {
    dispatch(
      Creators.updateAssetIndicatorWeightRequest({
        ...selectedIndicator,
        weight: selectedWeight,
      })
    );
    setSelectedIndicator({} as AssetIndicator);
    toggleModalOpen();
  }

  const fixedTimePeriods = [
    ...timeList.map((time) => {
      return {
        id: time[0],
        content: time[1],
        tooltip: time[2],
      };
    }),
  ];

  return (
    <Media
      queries={{
        tablet: supportedDevices.tablet,
      }}
    >
      {(matches) => (
        <>
          <Container>
            {isLogicalAsset && assetData.indicators.length === 0 ? (
              <div className="loader">
                <CircularProgress size={50} />
              </div>
            ) : (
              <>
                <div className="time-selector-container">
                  {matches.tablet && <h3>Período</h3>}
                  <DateFilter
                    fixedFilters={fixedTimePeriods}
                    initialValue="30m"
                    timePeriodState="assetData"
                  />
                </div>
                {!isLogicalAsset ? (
                  <>
                    <div className="graph-container">
                      <div className="chart">
                        <iframe
                          title="Informações do ativo"
                          src={config.grafana.getHealthPanelUrl(
                            assetData.data.identifier,
                            assetData.assetTimePeriod
                          )}
                          width="100%"
                          height="100%"
                          frameBorder="0"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="logical-charts">
                    <div>
                      <div className="indicator-editor">
                        <div className="iframe-container health-iframe">
                          <h4 className="health-label">Saúde</h4>
                          <iframe
                            title="Health"
                            src={config.grafana.getLogicalAssetIndicatorValueUrl(
                              assetData.data.identifier,
                              'health',
                              assetData.assetTimePeriod
                            )}
                            width={matches.tablet ? '66.6%' : '100%'}
                            height="48px"
                            frameBorder="0"
                          />
                        </div>
                      </div>
                      {assetData.indicators
                        .filter((indicator) => indicator.weight !== null)
                        .map((indicator) => (
                          <div
                            key={indicator.databaseName}
                            className="indicator-editor"
                          >
                            <div className="iframe-container">
                              <h4>{indicator.name}</h4>
                              <iframe
                                title={indicator.name}
                                src={config.grafana.getLogicalAssetIndicatorValueUrl(
                                  assetData.data.identifier,
                                  indicator.databaseName,
                                  assetData.assetTimePeriod
                                )}
                                width="100%"
                                height="48px"
                                frameBorder="0"
                              />
                            </div>
                            <WeightContainer
                              isEditMode={
                                selectedIndicator.databaseName ===
                                indicator.databaseName
                              }
                            >
                              <div className="label-container">
                                <div className="label">
                                  <p>Peso: </p>
                                  <p>
                                    {`${indicator.weight} (${Math.fround(
                                      indicator.weightPercentage * 100
                                    ).toFixed(2)}%)`}
                                  </p>
                                </div>
                                <IconButton
                                  disabled={assetData.areIndicatorsLoading}
                                  onClick={() =>
                                    handleEditingMode(
                                      indicator,
                                      indicator.weight
                                    )
                                  }
                                >
                                  <Edit fontSize="large" />
                                </IconButton>
                              </div>
                              <div className="select-container">
                                <CustomSelect
                                  value={selectedWeight}
                                  onChange={handleIndicatorWeightChange}
                                  fullWidth
                                  input={<CustomInput />}
                                  disabled={assetData.areIndicatorsLoading}
                                >
                                  {Array.from(Array(6), (_, i) => (
                                    <MenuItem key={i} value={i}>
                                      {i}
                                    </MenuItem>
                                  ))}
                                </CustomSelect>
                                <IconButton
                                  onClick={() => handleCheckPress(indicator)}
                                >
                                  <Check fontSize="large" />
                                </IconButton>
                                <IconButton onClick={handleCancelPress}>
                                  <Close fontSize="large" />
                                </IconButton>
                              </div>
                            </WeightContainer>
                          </div>
                        ))}
                    </div>
                    <iframe
                      title="Radar Chart"
                      src={config.grafana.getLogicalAssetRadarChartUrl(
                        assetData.data.identifier,
                        assetData.assetTimePeriod
                      )}
                      width="40%"
                      height="420px"
                      frameBorder="0"
                    />
                  </div>
                )}
              </>
            )}
          </Container>
          <CustomDialog
            open={isModalOpen}
            title={`Alterar peso do indicador: ${selectedIndicator.name}`}
            body={
              <p>
                {`Você tem certeza que deseja alterar o peso do indicador de ${selectedIndicator.weight} para ${selectedWeight}?`}
              </p>
            }
            action={{
              label: 'confirmar',
              onClick: handleModalConfirmClick,
            }}
            onClose={() => toggleModalOpen()}
          />
        </>
      )}
    </Media>
  );
};
