import { Menu, Button } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../../../../../../../styles/supportedDevices';

export const Container = styled.div`
  hr {
    margin: 10px 0px;
  }
`;

export const CustomHeader = styled.header`
  display: flex;
  justify-content: space-between;

  & .info-container {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: flex-start;
    color: black;

    h1 {
      font-size: 32px;
      font-weight: bold;
    }

    h2 {
      font-size: 20px;
    }

    & .breadcrumb-container {
      display: flex;
      flex-flow: row;
      margin-top: 15px;
      margin-bottom: 10px;
      flex-wrap: wrap;

      Button {
        width: 120px;
        margin-right: 5px;
        margin-bottom: 5px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;

        &.gradient {
          background-image: linear-gradient(to right, #19b0a0, #009ee2);
          color: white;
        }
      }
    }

    .title-actions-container {
      display: flex;
      align-items: center;

      & > * {
        margin-right: 15px;
      }
    }
  }

  & .health-indicator-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      text-align: center;
      font-size: 1.8em;
      color: black;
    }
  }
`;

export const ActionButton = styled(Button)`
  && {
    background-image: linear-gradient(to right, #19b0a0, #009ee2);
    color: white;
    justify-content: center;
    max-height: 27px;
  }
`;

export const TooltipSpan = styled.span`
  font-size: 12px;
`;

export const CustomMenu = styled(Menu)`
  & .MuiPaper-root {
    background-color: ${(props) => props.theme.palette.secondary.main};
    min-width: 120px;
  }

  & .MuiMenuItem-root {
    font-size: 14px;
  }

  & .MuiMenuItem-root:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const FilterBar = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .search-container {
    width: 160px;

    @media ${supportedDevices.laptopP} {
      width: 220px;
    }

    @media ${supportedDevices.laptopL} {
      width: 350px;
    }

    @media ${supportedDevices.desktop} {
      width: 400px;
    }
  }

  .indicators-selector-container {
    width: 90px;

    @media ${supportedDevices.laptopP} {
      width: 160px;
    }

    @media ${supportedDevices.laptopL} {
      width: 200px;
    }

    @media ${supportedDevices.desktop} {
      width: 300px;
    }
  }

  & .indicator-and-toggles {
    display: flex;
    flex-wrap: wrap;
  }

  & .toggle-charts {
    display: flex;
    align-items: center;
  }
`;

export const KPISelectorContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-items: center;
`;

export const CriticalityOption = styled.div`
  display: flex;
  align-items: center;
  max-height: 30px;
  flex-grow: 1;
`;

export const CriticalityConfirmationButton = styled(Button)`
  && {
    background-color: rgb(198, 198, 198);
    background-image: linear-gradient(to right, rgb(25, 176, 160), rgb(0, 158, 226));
    border-bottom-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    color: white;
    font-size: 12px;
    min-height: 42px;
  }
`;

export const SliderContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;