import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Button, Divider, Grid, Paper, Slider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { ArrowForward, ArrowBack, LockOpen, Lock, Edit } from '@material-ui/icons';
import { HierarchyNode } from 'd3';

import { NodeData } from '../../../../../../../../models/TreeMapNode';
import { AppState } from '../../../../../../../../store/reducers';

import { CustomDialog } from '../../../../../../../../components/CustomDialog';
import { setChildrenAndImportance } from '../../../../../../../../api';
import { useChildrenAndImportanceStore } from '../../../../../../../../zustand/AssetStore';
import { toastr } from 'react-redux-toastr';

import {
  Container,
  DelayedFade,
  BreadcrumbsAndButtonContainer,
} from './styles';
import { NodesEnum } from '../../../../../../../../utils/sunburstNodesTypes';
import { SliderContainer } from '../../../DesktopGraphView/components/Header/styles';
import { SetChildrenAndImportanceData } from '../../../DesktopGraphView/components/Header';

interface HeaderProps {
  onFilterSelect: (node: HierarchyNode<NodeData>) => void;
  handleLayerBlock(): void;
  selectedIndicator: string;
}

export const Header: React.FC<HeaderProps> = ({
  onFilterSelect,
  handleLayerBlock,
  selectedIndicator,
}) => {
  const treeHealth = useSelector((state: AppState) => state.treeHealth);

  const [areBreadcrumbsShowing, setAreBreadcrumbsShowing] = useState(false);

  function toggleBreadcrumbsVisible() {
    setAreBreadcrumbsShowing(!areBreadcrumbsShowing);
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    childrenAndImportance,
    fetchChildrenAndImportance,
  } = useChildrenAndImportanceStore();

  useEffect(() => {
    if (treeHealth.selectedNode.data.uuid) {
      fetchChildrenAndImportance(treeHealth.selectedNode.data.uuid);
    }
  }, [treeHealth.selectedNode.data.uuid, fetchChildrenAndImportance]);



  const handleConfirmImportance = async () => {
    const jsonObject = importanceValues.reduce((acc: SetChildrenAndImportanceData, item, index) => {
      if (index === 0) {
        acc.assetUuid = treeHealth.selectedNode.data.uuid;
      }
      acc[index] = {
        uuid: item.uuid,
        importance: item.importance,
      };
      return acc;
    }, { assetUuid: '' });
    
    try {
      await setChildrenAndImportance(jsonObject);
  
      toastr.success('Importância dos níveis atualizada com sucesso!', '');
    
      toggleAssetModal(false);
    } catch (error) {
      toastr.error('Falha ao atualizar a importância dos níveis.', '');
    }
  };
  

  function toggleAssetModal(isOpen: boolean) {
    setIsModalOpen(isOpen);
  }

  const [importanceValues, setImportanceValues] = useState(
    childrenAndImportance && Array.isArray(childrenAndImportance)
      ? childrenAndImportance.map(item => ({
          uuid: item.b.uuid,
          importance: item.importance
        }))
      : []
  );

  useEffect(() => {
    if (childrenAndImportance && Array.isArray(childrenAndImportance)) {
      setImportanceValues(childrenAndImportance.map(item => ({
        uuid: item.b.uuid,
        importance: item.importance
      })));
    }
  }, [childrenAndImportance]);
  
  const handleImportanceChange = (uuid: string, value: number) => {
    setImportanceValues(prevState => prevState.map(item => 
      item.uuid === uuid ? { ...item, importance: value } : item
    ));
  };

  const calculateImportanceSum = () => {
    return importanceValues.reduce((sum, item) => sum + item.importance, 0);
  };
  
  const isSumCorrect = calculateImportanceSum() === 100;

  const breadcrumbs = treeHealth.sequence!.map((node, index, array) => (
    <React.Fragment key={node.data.identifier}>
      <DelayedFade
        in={areBreadcrumbsShowing}
        delay={index * 100}
        unmountOnExit
        mountOnEnter
      >
        <div>
          <Button
            style={{
              height: 'fit-content',
              borderRadius: 4,
              padding: '2px 7px',
            }}
            color="secondary"
            variant="contained"
            className={
              array.length - 1 === index ? 'breadcrumb gradient' : 'breadcrumb'
            }
            onClick={() => onFilterSelect(node)}
          >
            <Box flexDirection="column" width="100%">
              <p
                style={{
                  color: array.length - 1 === index ? '#f2f2f2' : '#595959',
                  fontSize: '0.8em',
                }}
              >
                {NodesEnum[node.data.type.toUpperCase()]}
              </p>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography noWrap component="span" variant="button">
                  {node.data.name}
                </Typography>
                <ArrowForward />
              </Box>
            </Box>
          </Button>
        </div>
      </DelayedFade>
    </React.Fragment>
  ));

  return (
    <Container>
      <h2>Índice de {selectedIndicator || 'Saúde'}</h2>
      <div className="breadcrumb-container">
        <div className="p-absolute center-vertical w-100 title-actions-container">
          <div>
            <DelayedFade
              in={!areBreadcrumbsShowing}
              unmountOnExit
              mountOnEnter
              delay={300}
            >
              <div>
                <Button
                  color="secondary"
                  variant="contained"
                  className="toggle-breadcrumbs-button forward"
                  onClick={toggleBreadcrumbsVisible}
                >
                  <ArrowForward />
                </Button>
              </div>
            </DelayedFade>
            <DelayedFade
              in={!areBreadcrumbsShowing}
              delay={400}
              unmountOnExit
              mountOnEnter
            >
              <Typography variant="h1" noWrap>
                {treeHealth.selectedNode?.data.name}
              </Typography>
            </DelayedFade>
          </div>
          <DelayedFade
            in={!areBreadcrumbsShowing}
            delay={500}
            unmountOnExit
            mountOnEnter
          >
            <div style={{display: "contents"}}>
              <Button
                color="secondary"
                variant="contained"
                className="lock-btn"
                onClick={handleLayerBlock}
              >
                {treeHealth.selectedNode.data.blocked ? <LockOpen /> : <Lock />}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                className="edit-btn"
                onClick={() => {toggleAssetModal(true);}}
              >
                <Edit />
              </Button>

            </div>
          </DelayedFade>
        </div>
        <BreadcrumbsAndButtonContainer
          isOpen={areBreadcrumbsShowing}
          className="p-absolute"
        >
          <div className="breadcrumbs">{breadcrumbs}</div>
          <DelayedFade in={areBreadcrumbsShowing}>
            <div>
              <Button
                color="secondary"
                variant="contained"
                className="toggle-breadcrumbs-button backward"
                onClick={toggleBreadcrumbsVisible}
              >
                <ArrowBack />
              </Button>
            </div>
          </DelayedFade>
        </BreadcrumbsAndButtonContainer>
      </div>

      <CustomDialog
              open={isModalOpen}
              onClose={() => toggleAssetModal(false)}
              width={600}
              title={<div style={{ textAlign: 'center' }}>Editor de importâncias</div>}
              body={
                <div>
                  A soma das importâncias deve ser 100,00%
                  <SliderContainer className="slider-container">
                    <h3 style={{ marginTop: '5%' }}>
                      {treeHealth.selectedNode.data.name}
                    </h3>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ width: '25%' }}><strong>Nível</strong></TableCell>
                                <TableCell style={{ width: '22%' }}><strong>Importância</strong></TableCell>
                                <TableCell style={{ flexGrow: 1 }}><strong></strong></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {childrenAndImportance && childrenAndImportance.length > 0 ? (
                                childrenAndImportance.map((item, index) => (
                                  <TableRow key={item.b.uuid}>
                                    <TableCell>{item.b.name}</TableCell>
                                    <TableCell>
                                      <TextField
                                        type="number"
                                        value={importanceValues[index]?.importance || 0}
                                        onChange={(e) => {
                                          const value = e.target.value;

                                          const parsedValue = parseFloat(value);
                                          if (!isNaN(parsedValue)) {
                                            const clampedValue = Math.min(Math.max(parsedValue, 0), 100);
                                            handleImportanceChange(item.b.uuid, clampedValue);
                                          }
                                        }}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        inputProps={{
                                          min: 0,
                                          max: 100,
                                          step: 0.1,
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Slider
                                        value={importanceValues[index]?.importance || 0}
                                        onChange={(_, value) => handleImportanceChange(item.b.uuid, value as number)}
                                        min={0}
                                        max={100}
                                        step={0.1}
                                        valueLabelDisplay="auto"
                                        valueLabelFormat={(value) => `${value}%`}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={3}>Nenhum dado disponível</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                    </SliderContainer>
                  <div style={{ marginTop: '10px' }}>
                    <strong
                      style={{
                        color: calculateImportanceSum() === 100 ? 'green' : 'red',
                      }}
                    >
                      Soma das importâncias: {calculateImportanceSum().toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                    </strong>
                  </div>
                </div>
                }
                action={{
                  onClick: () => {
                    if (isSumCorrect) {
                      handleConfirmImportance();
                    } else {
                      toastr.error('Falha ao atualizar a importância dos níveis.', 'A importância deve ser igual a 100,00%');
                    }
                  },
                  label: 'Confirmar',
                  disabled: !isSumCorrect,
                }}
              />
    </Container>
  );
};
