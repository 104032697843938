import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Typography,
  Tooltip,
  Fade,
  Divider,
  MenuItem,
  Checkbox,
  Box,
  Grid,
  Slider,
  TextField,
  TableContainer,
  Table,
  Paper,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
} from '@material-ui/core';
import { ArrowForward, ArrowDownward } from '@material-ui/icons';
import { HierarchyNode } from 'd3';

import sunburstOnIcon from '../../../../../../../../assets/new_icons/ic-switch-sunburst-select.svg';
import sunburstOffIcon from '../../../../../../../../assets/new_icons/ic-switch-sunburst-unselect.svg';
import treeMapOnIcon from '../../../../../../../../assets/new_icons/ic-switch-treemap-select.svg';
import treeMapOffIcon from '../../../../../../../../assets/new_icons/ic-switch-treemap-unselect.svg';
import { HealthIndicator } from '../../../../../../../../components/charts/HealthIndicator';
import {
  DateFilter,
  DateFilterItem,
} from '../../../../../../../../components/DateFilter';
import { SearchField } from '../../../../../../../../components/SearchField';
import { ToggleChartButton } from '../../../../../../../../components/ToggleChartButton';
import { NodeData } from '../../../../../../../../models/TreeMapNode';
import { Creators } from '../../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../../store/reducers';
import { SelectableIndicator } from '../../../../../../../../store/treeHealth/types';
import { HealthType, ChartType } from '../../../types';
import { NodesEnum } from '../../../../../../../../utils/sunburstNodesTypes';

import {
  CustomHeader,
  TooltipSpan,
  CustomMenu,
  Container,
  FilterBar,
  ActionButton,
  CriticalityOption,
  CriticalityConfirmationButton,
  SliderContainer
} from './styles';
import { CustomDialog } from '../../../../../../../../components/CustomDialog';
import { setChildrenAndImportance } from '../../../../../../../../api';
import { useChildrenAndImportanceStore } from '../../../../../../../../zustand/AssetStore';
import { toastr } from 'react-redux-toastr';

interface HeaderProps {
  onFilterSelect: (node: HierarchyNode<NodeData>) => void;
  healthType: HealthType;
  onToggleChart: (chart: ChartType) => void;
  isSunburstVisible: boolean;
  isTreeMapVisible: boolean;
  handleLayerBlock: () => void;
  timeList: DateFilterItem[];
}

interface ChildData {
  uuid: string;
  importance: number;
}

export interface SetChildrenAndImportanceData {
  [key: number]: ChildData;
  assetUuid: string;
}

export const Header: React.FC<HeaderProps> = ({
  onFilterSelect,
  healthType,
  onToggleChart,
  isSunburstVisible,
  isTreeMapVisible,
  handleLayerBlock,
  timeList,
}) => {
  const treeHealth = useSelector((state: AppState) => state.treeHealth);

  const dispatch = useDispatch();

  const [dropdownItems, setDropdownItems] = useState<HierarchyNode<NodeData>[]>(
    []
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    childrenAndImportance,
    fetchChildrenAndImportance,
  } = useChildrenAndImportanceStore();

  useEffect(() => {
    if (treeHealth.selectedNode.data.uuid) {
      fetchChildrenAndImportance(treeHealth.selectedNode.data.uuid);
    }
  }, [treeHealth.selectedNode.data.uuid, fetchChildrenAndImportance]);


  function handleDropdownClose() {
    setAnchorEl(null);
  }

  function buildDropdownItems(currentNode: HierarchyNode<NodeData>) {
    const newDropdownItems: HierarchyNode<NodeData>[] = [];

    if (!currentNode.parent)
      newDropdownItems.push(
        treeHealth.sequence![treeHealth.sequence!.length - 1]
      );
    else
      currentNode.parent!.children!.forEach((node) => {
        newDropdownItems.push(node);
      });

    setDropdownItems(newDropdownItems);
    return newDropdownItems.length;
  }

  const handleIndicatorSelection = (
    e: React.ChangeEvent,
    value: SelectableIndicator
  ) => {
    dispatch(Creators.setSelectedIndicator(value));
    dispatch(Creators.getTreeHealthRequest());
  };

  const handleDropdownClick = (
    event: React.MouseEvent<HTMLElement>,
    node: HierarchyNode<NodeData>
  ) => {
    const length = buildDropdownItems(node);
    if (length > 1) setAnchorEl(event.currentTarget);
  };

  function handleDropdownItemClick(node: HierarchyNode<NodeData>) {
    onFilterSelect(node);
    handleDropdownClose();
  }

  function handleSearchBarChange(_: any, node: HierarchyNode<NodeData> | null) {
    if (node) onFilterSelect(node);
    else onFilterSelect(treeHealth.sequence![0]);
  }

  const getInitialCriticalLevelsSize = () => {
    if (treeHealth.selectedCriticality != null) {
      const initialLevels = Array.from({ length: 7 }, (_, index) => ({
        index,
        checked: treeHealth.selectedCriticality.includes(index),
      }));
      return initialLevels;
    }
  };

  const [criticalLevelsSize, setCriticalLevelsSize] = useState(
    getInitialCriticalLevelsSize()
  );

  useEffect(() => {
    localStorage.setItem(
      'criticalLevelsSize',
      JSON.stringify(criticalLevelsSize)
    );
  }, [criticalLevelsSize]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const criticalLevels = [
    'Sem Criticidade',
    'Criticidade 1',
    'Criticidade 2',
    'Criticidade 3',
    'Criticidade 4',
    'Criticidade 5',
    'Confirmar',
  ];
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCriticality = (event, value) => {
    if (value !== null) {
      const index = value;
      const newCriticalLevelsSize = [...criticalLevelsSize];
      newCriticalLevelsSize[index] = {
        ...newCriticalLevelsSize[index],
        checked: !newCriticalLevelsSize[index].checked,
      };
      setCriticalLevelsSize(newCriticalLevelsSize);
      setSelectedOptions((prev) => {
        if (prev.includes(value)) {
          return prev.filter((item) => item !== value);
        } else {
          return [...prev, value];
        }
      });
    }
  };

  function handleCriticalityConfirm() {
    const checkedIndexes = [];
    criticalLevelsSize.forEach((item, index) => {
      if (item.checked && index < criticalLevelsSize.length - 1) {
        checkedIndexes.push(index);
      }
    });

    if (checkedIndexes.length !== 0) {
      dispatch(Creators.setSelectedCriticality(checkedIndexes));
      dispatch(Creators.getTreeHealthRequest());
    }
  }

  const handleOptionClick = (index) => (event) => {
    event.stopPropagation();
    const newCriticalLevelsSize = [...criticalLevelsSize];
    newCriticalLevelsSize[index] = {
      ...newCriticalLevelsSize[index],
      checked: !newCriticalLevelsSize[index].checked,
    };
    setCriticalLevelsSize(newCriticalLevelsSize);
  };

  const handleConfirmImportance = async () => {
    const jsonObject = importanceValues.reduce((acc: SetChildrenAndImportanceData, item, index) => {
      if (index === 0) {
        acc.assetUuid = treeHealth.selectedNode.data.uuid;
      }
      acc[index] = {
        uuid: item.uuid,
        importance: item.importance,
      };
      return acc;
    }, { assetUuid: '' });
    
    try {
      await setChildrenAndImportance(jsonObject);
  
      toastr.success('Importância dos níveis atualizada com sucesso!', '');
    
      toggleAssetModal(false);
    } catch (error) {
      toastr.error('Falha ao atualizar a importância dos níveis.', '');
    }
  };

  function toggleAssetModal(isOpen: boolean) {
    setIsModalOpen(isOpen);
  }

  const [importanceValues, setImportanceValues] = useState(
    childrenAndImportance && Array.isArray(childrenAndImportance)
      ? childrenAndImportance.map(item => ({
          uuid: item.b.uuid,
          importance: item.importance
        }))
      : []
  );

  useEffect(() => {
    if (childrenAndImportance && Array.isArray(childrenAndImportance)) {
      setImportanceValues(childrenAndImportance.map(item => ({
        uuid: item.b.uuid,
        importance: item.importance
      })));
    }
  }, [childrenAndImportance]);
  
  const handleImportanceChange = (uuid: string, value: number) => {
    setImportanceValues(prevState => prevState.map(item => 
      item.uuid === uuid ? { ...item, importance: value } : item
    ));
  };

  const calculateImportanceSum = () => {
    return importanceValues.reduce((sum, item) => sum + item.importance, 0);
  };
  
  const isSumCorrect = calculateImportanceSum() === 100;

  return (
    <Container>
      <CustomHeader>
        <div className="info-container">
          <div className="breadcrumb-container">
            {treeHealth.sequence!.map((node, index, array) =>
              array.length - 1 === index ? (
                <React.Fragment key={node.data.identifier}>
                  <Fade in>
                    <Tooltip
                      title={<TooltipSpan>{node.data.name}</TooltipSpan>}
                      enterDelay={500}
                    >
                      <Button
                        style={{
                          lineHeight: 1,
                          height: 'fit-content',
                          padding: '5px 0px',
                        }}
                        color="secondary"
                        variant="contained"
                        className="gradient"
                        onClick={(event) =>
                          index === 0
                            ? undefined
                            : handleDropdownClick(
                                event,
                                node as HierarchyNode<NodeData>
                              )
                        }
                        aria-controls="dropdown-menu"
                        aria-haspopup="true"
                      >
                        <Box flexDirection="column" width="100%">
                          <p
                            style={{
                              color: '#f2f2f2',
                              fontSize: '0.85em',
                            }}
                          >
                            {NodesEnum[node.data.type.toUpperCase()]}
                          </p>
                          <Divider style={{ margin: '1px 0 6px 0' }} />
                          <Box
                            display="flex"
                            justifyContent={`${
                              index === 0 ? 'center' : 'space-between'
                            }`}
                            alignItems="center"
                            padding="0px 7px"
                          >
                            <Typography
                              noWrap
                              component="span"
                              variant="button"
                              style={{ lineHeight: 'revert' }}
                            >
                              {node.data.name}
                            </Typography>
                            {index !== 0 && <ArrowDownward />}
                          </Box>
                        </Box>
                      </Button>
                    </Tooltip>
                  </Fade>
                  <CustomMenu
                    id="dropdown-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleDropdownClose}
                  >
                    {dropdownItems.map((n) => (
                      <MenuItem
                        key={n.data.identifier}
                        onClick={() => handleDropdownItemClick(n)}
                        selected={
                          n.data.identifier ===
                          treeHealth.selectedNode.data.identifier
                        }
                      >
                        {n.data.name}
                      </MenuItem>
                    ))}
                  </CustomMenu>
                </React.Fragment>
              ) : (
                <Fade in key={node.data.identifier}>
                  <Tooltip
                    title={<TooltipSpan>{node.data.name}</TooltipSpan>}
                    enterDelay={500}
                  >
                    <Button
                      style={{
                        lineHeight: 1,
                        height: 'fit-content',
                        padding: '5px 0px',
                      }}
                      color="secondary"
                      variant="contained"
                      onClick={() => onFilterSelect(node)}
                    >
                      <Box flexDirection="column" width="100%">
                        <p
                          style={{
                            color: '#535252',
                            fontSize: '0.85em',
                          }}
                        >
                          {NodesEnum[node.data.type.toUpperCase()]}
                        </p>
                        <Divider style={{ margin: '1px 0 6px 0' }} />
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          padding="0px 7px"
                        >
                          <Typography
                            noWrap
                            component="span"
                            variant="button"
                            style={{ lineHeight: 'revert' }}
                          >
                            {node.data.name}
                          </Typography>
                          <ArrowForward />
                        </Box>
                      </Box>
                    </Button>
                  </Tooltip>
                </Fade>
              )
            )}
          </div>
          <div className="title-actions-container">
            <h1>{treeHealth.selectedNode.data.name}</h1>
            {treeHealth.timePeriod?.startTime === null && (
              <ActionButton variant="contained" onClick={handleLayerBlock}>
                {treeHealth.selectedNode.data.blocked
                  ? 'Desbloquear'
                  : 'Bloquear'}
              </ActionButton>
            )}
            <ActionButton
              variant="contained"
              onClick={() => {
                toggleAssetModal(true);
              }}
            >
              {'Editar'}
            </ActionButton>

            <CustomDialog
              open={isModalOpen}
              onClose={() => toggleAssetModal(false)}
              width={600}
              title={<div style={{ textAlign: 'center' }}>Editor de importâncias</div>}
              body={
                <div>
                  A soma das importâncias deve ser 100,00%
                  <SliderContainer className="slider-container">
                    <h3 style={{ marginTop: '5%' }}>
                      {treeHealth.selectedNode.data.name}
                    </h3>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ width: '25%' }}><strong>Nível</strong></TableCell>
                                <TableCell style={{ width: '22%' }}><strong>Importância</strong></TableCell>
                                <TableCell style={{ flexGrow: 1 }}><strong></strong></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {childrenAndImportance && childrenAndImportance.length > 0 ? (
                                childrenAndImportance.map((item, index) => (
                                  <TableRow key={item.b.uuid}>
                                    <TableCell>{item.b.name}</TableCell>
                                    <TableCell>
                                      <TextField
                                        type="number"
                                        value={importanceValues[index]?.importance || 0}
                                        onChange={(e) => {
                                          const value = e.target.value;

                                          const parsedValue = parseFloat(value);
                                          if (!isNaN(parsedValue)) {
                                            const clampedValue = Math.min(Math.max(parsedValue, 0), 100);
                                            handleImportanceChange(item.b.uuid, clampedValue);
                                          }
                                        }}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        inputProps={{
                                          min: 0,
                                          max: 100,
                                          step: 0.1,
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Slider
                                        value={importanceValues[index]?.importance || 0}
                                        onChange={(_, value) => handleImportanceChange(item.b.uuid, value as number)}
                                        min={0}
                                        max={100}
                                        step={0.1}
                                        valueLabelDisplay="auto"
                                        valueLabelFormat={(value) => `${value}%`}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={3}>Nenhum dado disponível</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                    </SliderContainer>
                  <div style={{ marginTop: '10px' }}>
                    <strong
                      style={{
                        color: calculateImportanceSum() === 100 ? 'green' : 'red',
                      }}
                    >
                      Soma das importâncias: {calculateImportanceSum().toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                    </strong>
                  </div>
                </div>
                }
                action={{
                  onClick: () => {
                    if (isSumCorrect) {
                      handleConfirmImportance();
                    } else {
                      toastr.error('Falha ao atualizar a importância dos níveis.', 'A importância deve ser igual a 100,00%');
                    }
                  },
                  label: 'Confirmar',
                  disabled: !isSumCorrect,
                }}
              />

          </div>
        </div>
        <div className="health-indicator-container">
          <HealthIndicator
            size={33}
            value={treeHealth.selectedNode.data.value!}
            fontSize={32}
            healthType={healthType}
            width={185}
            selectedIndicator={treeHealth.selectedIndicator.name}
          />
        </div>
      </CustomHeader>
      <Divider variant="middle" />
      <FilterBar>
        <div className="search-container">
          <SearchField
            options={treeHealth.sequence![0].descendants()}
            getOptionLabel={(option: HierarchyNode<NodeData>) =>
              option.data.name
            }
            onChange={handleSearchBarChange}
          />
        </div>
        <div className="indicators-selector-container">
          <SearchField
            getOptionLabel={(option: SelectableIndicator) => option.name}
            options={treeHealth.selectableIndicatorsOptions}
            onChange={handleIndicatorSelection}
            showSearchIcon={false}
            placeholder="Selecionar Indicador"
            value={treeHealth.selectedIndicator}
            getOptionSelected={(
              option: SelectableIndicator,
              value: SelectableIndicator
            ) => option.uuid === value.uuid}
            disableClearable
          />
        </div>
        <div className="indicators-selector-container">
          <SearchField
            showSearchIcon={false}
            disableClearable={true}
            getOptionLabel={(option) => 'Criticidade'}
            options={criticalLevelsSize.map((_, index) => index)}
            value={treeHealth.selectedCriticality}
            onChange={(event, value) => {
              handleChangeCriticality(event, value);
            }}
            placeholder="Criticidade"
            renderOption={(option) => {
              if (option !== criticalLevelsSize.length - 1) {
                return (
                  <CriticalityOption onClick={handleOptionClick(option)}>
                    <Checkbox
                      color="default"
                      checked={criticalLevelsSize[option].checked}
                      onChange={(e) => e.stopPropagation()}
                    />
                    <Typography>{criticalLevels[option]}</Typography>
                  </CriticalityOption>
                );
              } else {
                return (
                  <CriticalityConfirmationButton
                    variant="contained"
                    onClick={handleCriticalityConfirm}
                  >
                    {'Confirmar'}
                  </CriticalityConfirmationButton>
                );
              }
            }}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
          />
        </div>
        <div className="indicator-and-toggles">
          <DateFilter
            fixedFilters={timeList}
            initialValue={
              treeHealth.data.name === 'Samarco' ? 'yesterday' : 'ATUAL'
            }
          />
          <div className="toggle-charts">
            <ToggleChartButton
              onIcon={sunburstOnIcon}
              offIcon={sunburstOffIcon}
              alt="Mostrar ou esconder gráfico sunburst"
              isSelected={isSunburstVisible}
              onToggle={() => onToggleChart('sunburst')}
            />
            <ToggleChartButton
              onIcon={treeMapOnIcon}
              offIcon={treeMapOffIcon}
              alt="Mostrar ou esconder gráfico sunburst"
              isSelected={isTreeMapVisible}
              onToggle={() => onToggleChart('treemap')}
              iconSize={45}
            />
          </div>
        </div>
      </FilterBar>    
    </Container>
  );
};
