import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import { useSelector, useDispatch } from 'react-redux';

import { CircularProgress } from '@material-ui/core';

import { DateFilter } from '../../../../../../../components/DateFilter';
import { SearchField } from '../../../../../../../components/SearchField';
import config from '../../../../../../../config';
import { Creators } from '../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../store/reducers';
import { Tags } from '../components/Tags';

import { supportedDevices } from '../../../../../../../styles/supportedDevices';
import { Container, LoaderContainer } from './styles';

const selectOptions = [
  { value: 'CB', label: 'CB' },
  { value: 'CK', label: 'CK' },
  { value: 'CONTCRUZ', label: 'CONT CRUZ' },
  { value: 'DB', label: 'DB' },
  { value: 'ERROINT', label: 'ERRO' },
  { value: 'KD', label: 'KD' },
  { value: 'KI', label: 'KI' },
  { value: 'KP', label: 'KP' },
  { value: 'MODE', label: 'MODE' },
  { value: 'MSH', label: 'MSH' },
  { value: 'MSL', label: 'MSL' },
  { value: 'MV', label: 'MV' },
  { value: 'PV', label: 'PV' },
  { value: 'PV_AH', label: 'PV_AH' },
  { value: 'PV_AHH', label: 'PV_AHH' },
  { value: 'PV_AL', label: 'PV_AL' },
  { value: 'PV_ALL', label: 'PV_ALL' },
  { value: 'SH', label: 'SH' },
  { value: 'SL', label: 'SL' },
  { value: 'SP', label: 'SP' },
  { value: 'TAUT', label: 'AUT' },
  { value: 'TCAS', label: 'CAS' },
  { value: 'TMV_NSAT', label: 'MV NSAT' },
  { value: 'TPVEST', label: 'PV EST' },
  { value: 'TPV_NSAT', label: 'PV NSAT' },
  { value: 'VN', label: 'VN' },
  { value: 'health', label: 'Saúde' },
];

const timeList = [
  ['30m', '30 M', '30 Minutos'],
  ['1h', '1 H', '1 Hora'],
  ['6h', '6 H', '6 Horas'],
  ['12h', '12 H', '12 Horas'],
  ['24h', '24 H', '24 Horas'],
  ['7d', '7 D', '7 Dias'],
];

interface ComboBoxOptions {
  [key: string]: { value: string; label: string };
}

interface DataAnalysisTabProps {
  isLogicalAsset: boolean;
}

export const DataAnalysisTab: React.FC<DataAnalysisTabProps> = ({
  isLogicalAsset,
}) => {
  const { assetData, tags, notifications } = useSelector(
    (state: AppState) => state
  );

  const dispatch = useDispatch();

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<ComboBoxOptions>({
    first: { value: 'MV', label: 'MV' },
    second: { value: 'PV', label: 'PV' },
  });

  useEffect(() => {
    if (!isLogicalAsset)
      dispatch(Creators.getTagsRequest({ assetUuid: assetData.data.uuid }));
  }, [dispatch, assetData.data.uuid, isLogicalAsset]);

  // Sets selected tag based on selected notification
  useEffect(() => {
    if (notifications.currentNotification) {
      const tag = notifications.data
        .find((n) => n.id === notifications.currentNotification)
        ?.uuidTag?.split('.')[0];
      if (tag) setSelectedTags([tag]);
    }
  }, [notifications.currentNotification, notifications.data]);

  function handleSelectedTag(tag: string) {
    dispatch(Creators.setCurrentNotification(null));

    const newList = [...selectedTags];

    const index = newList.indexOf(tag);
    if (index !== -1) newList.splice(index, 1);
    else newList.push(tag);

    setSelectedTags(newList);
  }

  function handleSelectChange(name: 'first' | 'second', value: any) {
    if (value)
      setSelectedOptions({
        ...selectedOptions,
        [name]: value,
      });
  }

  function handleOptionSelected(
    option: { value: string; label: string },
    value: { value: string; label: string }
  ) {
    return option.value === value.value;
  }

  const fixedTimePeriods = [
    ...timeList.map((time) => {
      return {
        id: time[0],
        content: time[1],
        tooltip: time[2],
      };
    }),
  ];

  if (tags.isLoading)
    return (
      <LoaderContainer>
        <CircularProgress size={50} />
      </LoaderContainer>
    );

  return (
    <Media queries={{ tablet: supportedDevices.tablet }}>
      {(matches) => (
        <Container>
          <div className="time-selector-container">
            {matches.tablet && <h3>Período</h3>}
            <DateFilter
              fixedFilters={fixedTimePeriods}
              initialValue="30m"
              timePeriodState="assetData"
            />
          </div>
          {!isLogicalAsset ? (
            <>
              <div className="graph-container">
                <iframe
                  title="Informações do ativo"
                  src={config.grafana.getTagsPanelUrl(
                    {
                      startTime:
                        assetData.assetTimePeriod.startTime || 'now-30m',
                      endTime: assetData.assetTimePeriod.endTime || 'now',
                    },
                    assetData.data.identifier,
                    selectedTags
                  )}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                />
              </div>
              <div className="tags-container">
                <Tags
                  selectedTags={selectedTags}
                  handleSelectTag={handleSelectedTag}
                />
              </div>
            </>
          ) : (
            <div className="logical-container">
              <div className="selects-container">
                <SearchField
                  options={selectOptions}
                  getOptionLabel={(option: { value: string; label: string }) =>
                    option.label
                  }
                  onChange={(_, value) => handleSelectChange('first', value)}
                  getOptionSelected={handleOptionSelected}
                  value={selectedOptions.first}
                  placeholder="Eixo X"
                  showSearchIcon={false}
                />
                <SearchField
                  options={selectOptions}
                  getOptionLabel={(option: { value: string; label: string }) =>
                    option.label
                  }
                  onChange={(_, value) => handleSelectChange('second', value)}
                  getOptionSelected={handleOptionSelected}
                  value={selectedOptions.second}
                  placeholder="Eixo Y"
                  showSearchIcon={false}
                />
              </div>
              <div className="graph-container logical">
                <iframe
                  title="Analise de Dados do Ativo"
                  src={config.grafana.getLogicalAssetDataAnalysisDashboardUrl(
                    {
                      startTime:
                        assetData.assetTimePeriod.startTime || 'now-30m',
                      endTime: assetData.assetTimePeriod.endTime || 'now',
                    },
                    assetData.data.identifier,
                    selectedOptions.first.value,
                    selectedOptions.second.value,
                    assetData.data.model
                  )}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                />
              </div>
            </div>
          )}
        </Container>
      )}
    </Media>
  );
};
