import React from 'react';

import { Backdrop, Fade } from '@material-ui/core';

import { CustomModal, CustomButton } from './styles';

interface ActionProps {
  onClick(): void;
  label: string;
  disabled?: boolean;
}

interface CustomDialogProps {
  open: boolean;
  title: string | React.ReactNode;
  body: React.ReactNode;
  action: ActionProps;
  onClose(): void;
  width?: number;
  dataTestid?: string;
}

export const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  title,
  body,
  action,
  onClose,
  width,
  dataTestid,
}) => {
  return (
    <CustomModal
      width={width}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="outline-0">
          <div className="dialog-content" data-testid={dataTestid}>
            <div className="title-container">
              <h3>{title}</h3>
            </div>
            <div className="body-container">{body}</div>
            <div className="actions-container">
              <CustomButton variant="contained" onClick={onClose}>
                cancelar
              </CustomButton>
              <CustomButton
                variant="contained"
                className="gradient"
                onClick={action.onClick}
              >
                {action.label}
              </CustomButton>
            </div>
          </div>
        </div>
      </Fade>
    </CustomModal>
  );
};
